<template>
  <section>
    <login-form />
  </section>
</template>

<script>
export default {
  name: 'Login',
  components: {
    LoginForm: () => import('@/components/Access/LoginForm.vue')
  }
}
</script>

<style lang="scss" scoped>

</style>
